import React from "react";
import ReactDOM from "react-dom/client";
import ComingSoon from "./Components/ComingSoon";

const AppLayout = () => {
  return (
    <div className="app">
      <ComingSoon />
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppLayout />);
